import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../Styles/main.css";
import "../Styles/index.css";
import Nav from "../components/Nav";
import Logo from "../Images/bonsaiSocIcon.png";
import Facebook from "../Images/Facebook.png";
import Instagram from "../Images/Instagram.png";
import TikTok from "../Images/TikTok.png";

function Home() {
  const folderId = process.env.REACT_APP_FOLDERID;
  const apiKey = process.env.REACT_APP_API_KEY;
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&key=${apiKey}&fields=files(id,name,mimeType,thumbnailLink,webContentLink)`;
        const response = await fetch(url);
        const data = await response.json();

        console.log("API Response:", data);

        if (Array.isArray(data.files)) {
          setImages(data.files);
          console.log("Images array:", data.files);
        } else {
          setImages([]);
          console.log("No files found in the response.");
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching images:", error);
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [folderId, apiKey]);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    const blurAmount = Math.min(scrollPosition / 1.4, 50);

    const blurElement = document.getElementById("blur-overlay");
    if (blurElement) {
      blurElement.style.backdropFilter = `blur(${blurAmount}px)`;
      blurElement.style.webkitBackdropFilter = `blur(${blurAmount}px)`; // For Safari
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Southend Bonsai Society | Home</title>
        <meta
          name="description"
          content="Welcome to the Southend Bonsai Society's homepage. Explore our community and learn about bonsai care."
        />
        <meta
          name="keywords"
          content="Southend Bonsai Society, bonsai care, bonsai community"
        />
        <meta property="og:title" content="Southend Bonsai Society | Home" />
        <meta
          property="og:description"
          content="Explore the Southend Bonsai Society's homepage and learn about bonsai care."
        />
      </Helmet>
      <link rel="stylesheet" href="/public/Styles/main.css" />
      <link rel="stylesheet" href="/public/Styles/index.css" />
      <link rel="icon" href="/public/Images/bonsaiSocIcon.ico" />
      <Nav />
      <div id="content-wrapper">
        <div id="blur-overlay">
          <div id="main">
            <div id="center">
              <div className="content-container">
                <h1>Southend Bonsai Society</h1>
                <img className="icon" alt="Icon" src={Logo} />
              </div>
              <p>
                This group is for Amateur and Professional Bonsai enthusiasts.
                Based in Ashingdon, Rochford but open to everyone. This group
                enables everyone to share advice and knowledge or just to chat
                informally with other enthusiasts. We have a regular meeting on
                the 3rd Friday of every month, held in Ashingdon, with guest
                speakers, workshops and Bonsai shows.
              </p>
              <div className="gallery-container">
                {/* Display images here */}
                {isLoading ? (
                  <p>Loading...</p>
                ) : error ? (
                  <p>Error loading images</p>
                ) : images.length > 0 ? (
                  <div id="gallery-container">
                    <div id="gallery">
                      {images.map((file, index) => (
                        <img
                          key={file.id}
                          src={
                            file.thumbnailLink ||
                            file.webContentLink ||
                            `https://drive.google.com/uc?export=view&id=${file.id}`
                          }
                          alt={file.name}
                          className={`slide ${
                            index === currentImageIndex ? "active" : ""
                          }`}
                        />
                      ))}
                    </div>
                    <button id="prevBtn" onClick={handlePrev}>
                      Previous
                    </button>
                    <button id="nextBtn" onClick={handleNext}>
                      Next
                    </button>
                  </div>
                ) : (
                  <p>No Images Found</p>
                )}
              </div>
              <div>
                <a href="https://www.facebook.com/groups/1008888336687730">
                  <img
                    src={Facebook}
                    alt="FacebookIcon"
                    className="socialLogo"
                  />
                </a>
                <a href="https://www.instagram.com/southend_bonsai_club">
                  <img
                    className="socialLogo"
                    alt="InstagramIcon"
                    src={Instagram}
                  />
                </a>
                <a href="https://www.tiktok.com/@southend.bonsai.c">
                  <img className="socialLogo" alt="TikTokIcon" src={TikTok} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
